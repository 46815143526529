
import { defineComponent, reactive, ref } from 'vue';
import TwitchCategorySelect from './components/TwitchCategorySelect.vue';
import TextInput from './components/TextInput.vue';
import Toggle from './components/Toggle.vue';
import Language from './components/Language.vue';
import BroadcasterInfo from './components/BroadcasterInfo.vue';

import api$ from '@/services/tau-apis';

import { useStore } from 'vuex';

export default defineComponent({
  name: 'ChannelUpdate',
  components: {
    TwitchCategorySelect,
    TextInput,
    Language,
    Toggle,
    BroadcasterInfo,
  },
  setup() {
    const store = useStore();
    const display = ref(true);
    const testData = reactive({
      title: '',
      language: '',
      is_mature: false,
      category_id: '',
      category_name: '',
      broadcaster_user_id: '',
      broadcaster_user_name: '',
      broadcaster_user_login: '',
    });

    const close = () => {
      store.dispatch('UI/clearTestFormView');
    };
    const catChange = (ev: any) => {
      console.log(ev);
    };
    const submit = () => {
      console.log(testData);
      api$.tau.post('twitch-events/channel-update/test', testData);
      display.value = false;
    };

    return {
      testData,
      close,
      submit,
      catChange,
      display,
    };
  },
});
