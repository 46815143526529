
import { defineComponent, reactive, ref } from 'vue';
import BroadcasterInfo from './components/BroadcasterInfo.vue';
import TwitchUser from './components/TwitchUser.vue';
import NumberInput from './components/NumberInput.vue';

import api$ from '@/services/tau-apis';

import { useStore } from 'vuex';

export default defineComponent({
  name: 'ChannelRaid',
  components: {
    BroadcasterInfo,
    TwitchUser,
    NumberInput,
  },
  setup() {
    const store = useStore();
    const display = ref(true);
    const testData = reactive({
      from_broadcaster_user_id: '',
      from_broadcaster_user_name: '',
      from_broadcaster_user_login: '',
      to_broadcaster_user_id: '',
      to_broadcaster_user_name: '',
      to_broadcaster_user_login: '',
      viewers: 0,
    });

    const close = () => {
      store.dispatch('UI/clearTestFormView');
    };
    const submit = () => {
      api$.tau.post('twitch-events/channel-raid/test', testData);
    };

    return {
      testData,
      close,
      submit,
      display,
    };
  },
});
