
import {
  defineComponent,
  computed,
  onMounted,
  inject,
  resolveComponent,
  ComponentOptions,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';

import Prism from 'vue-prism-component';
import TestForm from '../test-forms/TestForm.vue';

import {
  TauStatusWsService,
  TauTwitchEventWsService,
} from '@/services/tau-api-ws';

import { TwitchEvent, eventTitleMap } from '@/models/twitch-event';
import { EventSubscription } from '@/models/event-subscription';
import { Broadcaster } from '@/models/broadcaster';

import api$ from '@/services/tau-apis';

export default defineComponent({
  name: 'Dashboard',
  components: { Prism, TestForm },
  setup() {
    const store = useStore();

    const data = computed(() => {
      return store.getters['eventSubscriptions/active'];
    });

    const broadcaster = computed<Broadcaster>(() => {
      return store.getters['broadcaster/data'];
    });

    const twitchEvents = computed(() => {
      return store.getters['twitchEvents/all'];
    });

    const fetchEventSubscriptions = async () => {
      await store.dispatch('twitchEvents/loadAll');
      await store.dispatch('broadcaster/load');
      tauStatusWs.connect();
      twitchEventWs.connect();
    };

    const componentExists = (view: EventSubscription) => {
      const componentName = _.startCase(
        _.camelCase(view.lookup_name.replaceAll('_', '-')),
      ).replace(/ /g, '');
      console.log(componentName);
      const formExists =
        componentName in
        ((resolveComponent('test-form') as ComponentOptions)?.components || {});
      return formExists;
    };

    const tauStatusWs = inject('tauStatusWs') as TauStatusWsService;
    const twitchEventWs = inject('twitchEventWs') as TauTwitchEventWsService;

    onMounted(fetchEventSubscriptions);

    function openTestDialog(view: EventSubscription) {
      view.lookup_name = view.lookup_name.replaceAll('_', '-');
      //.replace('channel-channel', 'channel');

      store.dispatch('UI/setTestFormView', view);
    }

    function twitchEventTitle(twitchEvent: TwitchEvent) {
      const msgSource =
        twitchEvent.origin === 'replay'
          ? '[Replay] '
          : twitchEvent.origin === 'test'
          ? '[Test] '
          : '';
      return twitchEvent.event_type in eventTitleMap
        ? msgSource + eventTitleMap[twitchEvent.event_type](twitchEvent)
        : msgSource + eventTitleMap['default'](twitchEvent);
    }

    function replay(twitchEvent: TwitchEvent) {
      store.dispatch('twitchEvents/replay', twitchEvent);
    }

    function replayTest(twitchEvent: TwitchEvent) {
      api$.tau.post(
        `twitch-events/${twitchEvent.event_type}/test`,
        twitchEvent.event_data,
      );
    }

    return {
      data,
      broadcaster,
      twitchEvents,
      twitchEventTitle,
      componentExists,
      replay,
      replayTest,
      openTestDialog,
    };
  },
});
