
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore();
    const router = useRouter();

    const username = ref();
    const password = ref();

    const error = computed(function () {
      return store.getters['auth/error'];
    });

    async function login() {
      const success = await store.dispatch('auth/login', {
        username: username.value,
        password: password.value,
      });
      if (success) {
        router.replace('/');
      }
    }

    return {
      username,
      password,
      login,
      error,
    };
  },
});
