
import { defineComponent, reactive, ref } from 'vue';
import TextInput from './components/TextInput.vue';
import GoalTypeSelect from './components/GoalTypeSelect.vue';
import NumberInput from './components/NumberInput.vue';
import BroadcasterInfo from './components/BroadcasterInfo.vue';

import api$ from '@/services/tau-apis';

import { useStore } from 'vuex';

export default defineComponent({
  name: 'ChannelUpdate',
  components: {
    TextInput,
    GoalTypeSelect,
    NumberInput,
    BroadcasterInfo,
  },
  setup() {
    const store = useStore();
    const display = ref(true);
    const testData = reactive({
      id: Array.from(Array(27), () =>
        Math.floor(Math.random() * 36).toString(36),
      ).join(''),
      type: 'follow',
      started_at: new Date().toISOString(),
      description: '',
      target_amount: 30,
      current_amount: 23,
      broadcaster_user_id: '',
      broadcaster_user_name: '',
      broadcaster_user_login: '',
    });

    const close = () => {
      store.dispatch('UI/clearTestFormView');
    };
    const submit = () => {
      api$.tau.post('twitch-events/channel-goal-begin/test', testData);
    };

    return {
      testData,
      close,
      submit,
      display,
    };
  },
});
