
import { format } from 'date-fns';
import { defineComponent, reactive, ref } from 'vue';
import BroadcasterInfo from './components/BroadcasterInfo.vue';
import TwitchUser from './components/TwitchUser.vue';

import api$ from '@/services/tau-apis';

import { useStore } from 'vuex';

export default defineComponent({
  name: 'ChannelFollow',
  components: {
    BroadcasterInfo,
    TwitchUser,
  },
  setup() {
    const store = useStore();
    const test = ref('test');
    const display = ref(true);
    const testData = reactive({
      user_id: '',
      user_name: '',
      user_login: '',
      followed_at: '',
      broadcaster_user_id: '',
      broadcaster_user_name: '',
      broadcaster_user_login: '',
    });

    const hide = () => {
      console.log('Hide!');
    };
    const close = () => {
      store.dispatch('UI/clearTestFormView');
    };
    const catChange = (ev: any) => {
      console.log(ev);
    };
    const submit = () => {
      testData.followed_at = new Date().toISOString();
      api$.tau.post('twitch-events/channel-follow/test', testData);
      // display.value = false;
    };

    return {
      test,
      testData,
      close,
      submit,
      catChange,
      display,
      hide,
    };
  },
});
