
import { defineComponent, ref, PropType } from 'vue';

export default defineComponent({
  name: 'TextInput',
  emits: ['update:value'],
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const inputValue = ref<string>();

    function onChange(event: any) {
      updateModel(event.target.value);
    }

    function updateModel(newValue: any) {
      emit('update:value', newValue);
    }

    return {
      onChange,
      inputValue,
    };
  },
});
