
import { defineComponent, reactive, ref } from 'vue';
import BroadcasterInfo from './components/BroadcasterInfo.vue';
import TwitchUser from './components/TwitchUser.vue';
import RewardSelect from './components/RewardSelect.vue';
import EmoteMessage from './components/EmoteMessage.vue';
import { Reward } from './components/RewardSelect.vue';

import api$ from '@/services/tau-apis';

import { useStore } from 'vuex';
import { TwitchMessage } from './components/EmoteMessage.vue';
import { v4 as uuidv4 } from 'uuid';

interface RewardRedemptionData {
  id: string;
  reward: Reward | null;
  status: string;
  user_id: string;
  user_name: string;
  user_login: string;
  user_input: string | TwitchMessage;
  redeemed_at: string;
  broadcaster_user_id: string;
  broadcaster_user_name: string;
  broadcaster_user_login: string;
}

export default defineComponent({
  name: 'ChannelChannelPointsCustomRewardRedemptionAdd',
  components: {
    BroadcasterInfo,
    TwitchUser,
    RewardSelect,
    EmoteMessage,
  },
  setup() {
    const store = useStore();
    const display = ref(true);
    const testData = reactive<RewardRedemptionData>({
      id: '',
      reward: null,
      status: 'unfulfilled',
      user_id: '',
      user_name: '',
      user_login: '',
      user_input: '',
      redeemed_at: '',
      broadcaster_user_id: '',
      broadcaster_user_name: '',
      broadcaster_user_login: '',
    });

    const close = () => {
      store.dispatch('UI/clearTestFormView');
    };
    const submit = () => {
      const payload: RewardRedemptionData = {
        ...testData,
        id: uuidv4(),
        reward: testData.reward
          ? {
              id: testData.reward.id,
              cost: testData.reward.cost,
              title: testData.reward.title,
              prompt: testData.reward.prompt,
            }
          : null,
        status: testData.reward?.should_redemptions_skip_request_queue
          ? 'fulfilled'
          : 'unfulfilled',
        redeemed_at: new Date().toISOString(),
      };

      if (testData.reward?.is_user_input_required) {
        if (typeof testData.user_input !== 'string') {
          payload.user_input = testData.user_input.text;
        } else {
          payload.user_input = testData.user_input;
        }
      } else {
        payload.user_input = '';
      }
      api$.tau.post(
        'twitch-events/channel-channel_points_custom_reward_redemption-add/test',
        payload,
      );
    };

    return {
      testData,
      close,
      submit,
      display,
    };
  },
});
