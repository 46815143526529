
import { defineComponent, reactive, ref } from 'vue';
import BroadcasterInfo from './components/BroadcasterInfo.vue';
import TwitchUser from './components/TwitchUser.vue';
import SubTierSelect from './components/SubTierSelect.vue';
import Toggle from './components/Toggle.vue';

import api$ from '@/services/tau-apis';

import { useStore } from 'vuex';

export default defineComponent({
  name: 'ChannelSubscriptionEnd',
  components: {
    BroadcasterInfo,
    TwitchUser,
    SubTierSelect,
    Toggle,
  },
  setup() {
    const store = useStore();
    const display = ref(true);
    const testData = reactive({
      user_id: '',
      user_name: '',
      user_login: '',
      broadcaster_user_id: '',
      broadcaster_user_name: '',
      broadcaster_user_login: '',
      tier: '',
      is_gift: false,
    });

    const close = () => {
      store.dispatch('UI/clearTestFormView');
    };

    const submit = () => {
      api$.tau.post('twitch-events/channel-subscription-end/test', testData);
    };

    return {
      testData,
      close,
      submit,
      display,
    };
  },
});
