
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import TopBar from './layout/TopBar.vue';
import SideBar from './layout/SideBar.vue';

export default defineComponent({
  name: 'Dashboard',
  components: {
    TopBar,
    SideBar,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    async function logout() {
      await store.dispatch('auth/logout');
      router.replace('/login');
    }

    async function fetchEventSubscriptions() {
      await store.dispatch('eventSubscriptions/loadAll');
    }

    onMounted(fetchEventSubscriptions);

    return {
      logout,
    };
  },
});
