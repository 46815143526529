
import { defineComponent, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default defineComponent({
  name: 'TopBar',
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    function logout() {
      context.emit('logout');
    }

    return {
      logout,
    };
  },
});
